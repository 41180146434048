/* PROMOS */
.card.promo-card {
    transition: transform 0.2s ease-in-out;
}

.card.promo-card img {
    border-radius: 15px;
}

.card.promo-card:hover {
    transform: scale(1.05);
}

.btn-disabled {
    cursor: not-allowed;
}

.bg-darkened {
    background-color: rgba(0, 0, 0, .4);
}

.redeemed-code {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.points-container {
    background-color: $primary;
}

.points-container .points {
    font-size: 28px;
    font-weight: bold;
}

.points-container .text {
    font-size: 17px;
    font-weight: bold;
}