/* LOADER */
body.body-loading {
    overflow: hidden;
}

.loader {
    width: 100vw;
    height: 100%;
    background-color: $bg-color;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.typing_loader{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
     -moz-animation: Typing 1s linear infinite alternate;
          animation: typing 1s linear infinite alternate;
  top: 49vh;
  margin:auto;
  position: relative;
  left: -12px;
}
@-webkit-keyframes typing{
  0%{
      background-color: rgba(0, 0, 0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
    }
  25%{ 
      background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
  }
  75%{ background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,1);
    }
}

@-moz-keyframes typing{
 0%{
      background-color: rgba(0, 0, 0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
    }
  25%{ 
      background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
  }
  75%{ background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,1);
    }
}

@keyframes typing{
 0%{
      background-color: rgba(0, 0, 0, 1);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
    }
  25%{ 
      background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,2), 
                  24px 0px 0px 0px rgba(0, 0, 0,0.2);
  }
  75%{ background-color: rgba(0, 0, 0, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(0, 0, 0,0.2), 
                  24px 0px 0px 0px rgba(0, 0, 0,1);
    }
}