/* COLORS */
$primary: #16A74A;
$accent: #292C7E;
$danger: #ea0707;
$secondary: #E78875;
$secondary-hover: #af5644;
$bg-color: #EEEEEE;
$white: #FFFFFF;


$headings:  Fellix, 'Fellix', serif, Arial;
$content: Fellix, 'Fellix', serif, Arial;